/*! Place your custom styles here */
.tripple {
  padding-top: 120px; }

.form-error, .form-success {
  padding-left: 4vw; }

@media (max-width: 1600px) {
  .form-error, .form-success {
    padding-left: 5vw; } }

@media (max-width: 1366px) {
  .form-error, .form-success {
    padding-left: 6vw; } }

@media (max-width: 1200px) {
  .form-error, .form-success {
    padding-left: 7vw; } }

@media (max-width: 1000px) {
  .form-error, .form-success {
    padding-left: 10vw; } }

@media (max-width: 768px) {
  .tripple {
    margin-bottom: -40px;
    padding-top: 50px; }
  .form-error, .form-success {
    padding-left: 20vw; } }

@media (max-width: 375px) {
  .form-error, .form-success {
    padding-left: 24vw; } }

@media (max-width: 320px) {
  .form-error, .form-success {
    padding-left: 28vw; } }

.back-to-top {
  right: 3em; }

#waterfall {
  margin: 10px; }

#waterfall li {
  left: 0;
  top: 0;
  opacity: 0;
  transform: translateY(100px); }

#waterfall li.show {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s, top 1s; }

#waterfall li > div {
  color: rgba(0, 0, 0, 0.6);
  font-size: 32px;
  border-radius: 3px;
  margin: 10px;
  padding: 15px;
  background: white;
  border: 1px solid rgba(38, 191, 64, 0);
  transition: all 0.5s; }

/*#waterfall li>div:hover {
    transform: translateY(-10px);
    border: 1px solid rgba(038, 191, 64, 1);
    box-shadow: 0 10px 15px rgba(038, 191, 64, 0.1);
    transition: all 0.3s;
}*/
[href="/press"]:hover {
  color: #7A2D40; }

.icon, .menu-toggle i {
  color: #4C6675; }
